import React from "react";
import style from "./CommunityCard.module.css";

const CommunityCard = ({ img, name, date, description }) => {
  return (
    <div className={style.all_block}>
      <div className={style.info_review}>
        <div className={style.info_user}>
          <img src={img} alt="avatar" />
          <span>{name}</span>
        </div>
        <div className={style.date_info}>
          <span>{date}</span>
        </div>
      </div>
      <div className={style.description_review}>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default CommunityCard;
