    import React from "react";
    import logo from "../../static/Logo.png";
    import BNB_Camera1 from "../../static/BNB_Camera1.png";
    import Ethereum_Camera2 from "../../static/Ethereum_Camera2.png";
    import Tether_Camera4 from "../../static/Tether_Camera4.png";
    import Solana_Camera2 from "../../static/Solana_Camera2.png"
    import Dai_Camera2 from "../../static/Dai_Camera2.png"
    import style from "./Header.module.css";
import Timer from "../Timer/Timer";

    const Header = () => {
    return (
        <header className={style.header}>
        <div className={style.images}>
            <img src={BNB_Camera1} alt="BNB Camera" className={style.bnb_camera} />
            <img src={Ethereum_Camera2} alt="Ethereum Camera" className={style.ethereum_camera} />
            <img src={Tether_Camera4} alt="Tether Camera" className={style.tether_camera} />
            <img src={Solana_Camera2} alt="Ethereum Camera" className={style.solana_camera} />
            <img src={Dai_Camera2} alt="Tether Camera" className={style.dai_camera} />
        </div>
        <div className={style.all_header}>
            <div className={style.logo_info}>
            <div className={style.block_logo}>
                <img src={logo} alt="Logo" className={style.image_logo}/>
            </div>
            <div>
                <span>ALIBABA | DeFi-Labs</span>
            </div>
            </div>
            <div className={style.block_timer}>
            <div className={style.info_timer}>
                <span>BETA ENDS IN</span>
            </div>
                <Timer />
            </div>
        </div>
        </header>
    );
    };

    export default Header;
