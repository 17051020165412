import React, { useEffect, useState } from "react";
import style from "./Timer.module.css";

const Timer = () => {
  // Retrieve the target date from localStorage if it exists
  const savedTargetDate = localStorage.getItem("targetDate");
  const targetDate = savedTargetDate ? new Date(savedTargetDate) : new Date();

  // If there is no saved target date, set it to 23 minutes from now
  if (!savedTargetDate) {
    targetDate.setMinutes(targetDate.getMinutes() + 23);
    localStorage.setItem("targetDate", targetDate);
  }

  // Function to calculate the remaining time until the target date
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={style.block_number_timer}>
      <div className={style.timer_section}>
        <div className={style.block_number}>
          <span className={style.number_timer}>{timeLeft.days}</span>
        </div>
        <div className={style.calculus}>
          <span>Days</span>
        </div>
      </div>
      <div className={style.block_colon}>
        <span>:</span>
      </div>
      <div className={style.timer_section}>
        <div className={style.block_number}>
          <span className={style.number_timer}>{timeLeft.hours}</span>
        </div>
        <div className={style.calculus}>
          <span>Hours</span>
        </div>
      </div>
      <div className={style.block_colon}>
        <span>:</span>
      </div>
      <div className={style.timer_section}>
        <div className={style.block_number}>
          <span className={style.number_timer}>{timeLeft.minutes}</span>
        </div>
        <div className={style.calculus}>
          <span>Minutes</span>
        </div>
      </div>
      <div className={style.block_colon}>
        <span>:</span>
      </div>
      <div className={style.timer_section}>
        <div className={style.block_number}>
          <span className={style.number_timer}>{timeLeft.seconds}</span>
        </div>
        <div className={style.calculus}>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default Timer;
