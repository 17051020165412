import SixthBlock from "./SixthBlock/SixthBlock";
import FirstBlock from "./FirstBlock/FirstBlock";
import FourthBlock from "./FourthBlock/FourthBlock";
import style from "./Main.module.css";
import SecondBlock from "./SecondBlock/SecondBlock";
import ThirdBlock from "./ThirdBlock/ThirdBlock";
import SeventhBlock from "./SeventhBlock/SeventhBlock";
import FifthBlock from "./FifthBlock/FifthBlock";
import EighthBlock from "./EighthBlock/EighthBlock";
import FaqBlock from "./FaqBlock/FaqBlock";
import PreFooter from "./PreFooter/PreFooter";

const Main = () => {
  return (
    <div className={style.body}>
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
      <FourthBlock />
      <FifthBlock />
      <SixthBlock />
      <SeventhBlock />
      <EighthBlock />
      <FaqBlock />
      <PreFooter />
    </div>
  );
};

export default Main;
