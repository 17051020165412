import React, { useState, useEffect } from "react";
import style from "./SixthBlock.module.css";

// Initial data for the table
const initialData = [
  {
    txHash: "0xDEAfCD6E...",
    block: "275304...",
    from: "2MwzqFH4DWkZ3...",
    to: "3J98t1WpEZ73C...",
    from1: "2MwzqFH4DWkZ3...",
    to1: "3J98t1WpEZ73C...",
    value: "22.59927 SOL",
    value1: "12.43643 SOL",
    fee: "0.0001 SOL",
    status: "Completed",
  },
  {
    txHash: "0xDEAfCD6E...",
    block: "275304...",
    from: "5Le6mwSgGZEKF...",
    to: "H6AvWTbn8eN6q...",
    from1: "5Le6mwSgGZEKF...",
    to1: "H6AvWTbn8eN6q...",
    value: "12.54436 SOL",
    value1: "11.64343 SOL",
    fee: "0.0001 SOL",
    status: "Completed",
  },
  {
    txHash: "0xABCD1234...",
    block: "275304...",
    from: "8VH9p3ZCvKpsV...",
    to: "FN13H2yTVBVLb...",
    from1: "8VH9p3ZCvKpsV...",
    to1: "FN13H2yTVBVLb...",
    value: "5.67890 SOL",
    value1: "9.56454 SOL",
    fee: "0.0001 SOL",
    status: "Completed",
  },
  {
    txHash: "0xEFGH5678...",
    block: "275304...",
    from: "7DfQt5cmv8UdH...",
    to: "9xQW2o5Hcz8Gp...",
    from1: "7DfQt5cmv8UdH...",
    to1: "9xQW2o5Hcz8Gp...",
    value: "8.12345 SOL",
    value1: "1.25353 SOL",
    fee: "0.0001 SOL",
    status: "Completed",
  },
];

const generateNewRow = () => {
  const newRow = {
    txHash: Math.random().toString(16).substring(2, 10) + "...",
    block: "275304...",
    from: Math.random().toString(16).substring(2, 15) + "...",
    to: Math.random().toString(16).substring(3, 15) + "...",
    from1: Math.random().toString(16).substring(2, 15) + "...",
    to1: Math.random().toString(16).substring(3, 15) + "...",
    value: (Math.random() * 100).toFixed(5) + " SOL",
    value1: (Math.random() * 100).toFixed(5) + " SOL",
    fee: (Number(Math.random() * 0.000094 + 0.000005).toFixed(6)) + " SOL",
    status: "Completed",
  };
  return newRow;
};

const SixthBlock = () => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => {
        const newData = generateNewRow();
        const updatedData = [newData, ...prevData.slice(0, prevData.length - 1)];
        return updatedData;
      });
    }, 550);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.all_block}>
      <div className={style.paragraph}>
        <span>LIVE PAYMENTS</span>
      </div>
      <div className={style.table}>
        <div className={style.table_info}>
          {/* Table Headers */}
          <div className={`${style.header} ${style.TxHash}`}>TxHash</div>
          <div className={`${style.header} ${style.Block}`}>Block</div>
          <div className={style.header}>From</div>
          <div className={style.header}>To</div>
          <div className={style.header}>Value</div>
          <div className={`${style.header} ${style.Fee}`}>Fee</div>
          <div className={style.header}>Status</div>

          {data.map((row, index) => (
            <>
              <div className={style.TxHash}>{row.txHash}</div>
              <div className={style.Block}>{row.block}</div>
              <div className={style.block_info}>
                <span>{row.from}</span>
                <span>{row.to}</span>
              </div>
              <div className={style.block_info}>
                <span>{row.from1}</span>
                <span>{row.to1}</span>
              </div>
              <div className={style.block_info}>
                <span>{row.value}</span>
                <span>{row.value1}</span>
              </div>
              <div className={style.Fee}>{row.fee}</div>
              <div className={style[`status-${row.status.toLowerCase()}`]}>{row.status}</div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SixthBlock;
