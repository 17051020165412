import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import style from "./ThirdBlock.module.css";

const ThirdBlock = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.5,
  });

  const fadeInRef = useRef(null);

  useEffect(() => {
    if (inView) {
      fadeInRef.current.classList.add(style.fadeIn);
    }
  }, [inView]);

  const formatNumber = (number) => {
    if (number >= 1000) {
      return `${Math.round(number / 1000)}K`;
    }
    return number;
  };

  return (
    <div className={`${style.all_block} ${style.fadeIn}`} ref={ref}>
      <div className={style.paragraph}>
        <span>Our records</span>
      </div>
      <div className={style.statistics} ref={fadeInRef}>
        <div className={style.info_block}>
          <span className={style.info_text}>Transactions per second</span>
          <span className={style.info_number}>
            {inView && (
              <CountUp
                start={0}
                end={20000}
                duration={2.75}
                formattingFn={formatNumber}
              />
            )}
          </span>
        </div>
        <div className={style.info_block_up}>
          <span className={style.info_text}>Participated</span>
          <span className={style.info_number}>
            {inView && (
              <CountUp
                start={0}
                end={179000}
                duration={2.75}
                formattingFn={formatNumber}
              />
            )}
          </span>
        </div>
        <div className={style.info_block}>
          <span className={style.info_text}>Average weekly APY</span>
          <span className={style.info_number}>
            {inView && (
              <CountUp start={0} end={88} duration={2.75} suffix="%" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ThirdBlock;
