import Timer from "../../Timer/Timer";
import style from "./PreFooter.module.css";

const PreFooter = () => {
  return (
    <div className={style.all_block}>
      <div className={style.info}>
          <p>Don't miss out on the algorithmic trading revolution</p>
          <p>Try it free of charge and without risk</p>
          <p>Start building your financial future now!</p>
      </div>
      <div className={style.timer}>
        <Timer />
      </div>
      <div className={style.block_connect}>
          <button class="connect">Connect wallet</button>
        </div>
    </div>
  );
};

export default PreFooter;
