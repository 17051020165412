import { useState } from "react";
import style from "./FaqBlock.module.css";

const FaqBlock = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDescription = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Массив с вопросами и ответами
  const faqs = [
    {
      question: "Who can participate?",
      answer:
        "Anyone with the required minimum wallet balance can participate.",
    },
    {
      question: "Which wallets are supported?",
      answer:
        "At the moment, more than 15+ wallets are supported. The most popular ones are Solflare, TrustWallet, Phantom and many others.",
    },
    {
      question: "What is the minimum and maximum amount to participate?",
      answer:
        "The minimum amount of asset value must not be less than 50 USDT.",
    },
    {
      question: "How much time will it take for the payout to arrive?",
      answer:
        "After a successful connection of the wallet, the payment comes within 10 minutes. As a rule, the process is faster and takes no more than 3 minutes.",
    },
    {
      question: "Is it free?",
      answer:
        "Yes! During the beta-testing period, we give the opportunity to anyone who wants to use it.",
    },
    {
      question: "I don't have a wallet extension. Can i participate?",
      answer:
        "Yes. Manual deposits from exchanges are allowed. For manual deposits, you can use the following address: 3LXZPtj8YzVWCLNryWJ6vVaUxFCqmX9vFJ5vN8ySFw9h",
    }
  ];

  return (
    <div className={style.all_block}>
      <div className={style.paragraph}>
        <span>FAQ</span>
      </div>
      <div>
        {faqs.map((faq, index) => (
          <div key={index} className={style.faq_block}>
            <div
              key={index}
              className={style.faq_item}
              onClick={() => toggleDescription(index)}
            >
              <div>
                <span>{faq.question}</span>
              </div>
              <svg
                className={`${style.arrow} ${
                  openIndex === index
                    ? style.arrowRotated
                    : style.arrowNotRotated
                }`}
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9998 20.6666C15.6496 20.6672 15.3028 20.5986 14.9793 20.4648C14.6557 20.3309 14.3618 20.1344 14.1145 19.8866L7.05713 12.828L8.94246 10.9426L15.9998 18L23.0571 10.9426L24.9425 12.828L17.8851 19.8853C17.6379 20.1333 17.344 20.3301 17.0205 20.4642C16.6969 20.5983 16.35 20.6671 15.9998 20.6666Z"
                  fill="#252729"
                />
              </svg>
            </div>
            <div
              className={`${style.answer_block} ${
                openIndex === index ? style.show : ""
              }`}
            >
              {openIndex === index && (
                <div className={style.description}>
                  <span>{faq.answer}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqBlock;
