import { useState } from "react";
import style from "./FifthBlock.module.css";
import CommunityCard from "../../CommunityCard/CommunityCard"
import avatar from "../../../static/152039525_3639840766133910_4329489655276428896_n.jpg";
import avatar2 from "../../../static/2zbxr0Bs_400x400.jpg";
import avatar3 from "../../../static/372635290_833921404956884_3564445803777929460_n.jpg";
import avatar4 from "../../../static/57821669_10217421040571564_1556068794529480704_n.jpg";
import avatar5 from "../../../static/4oJreCdB_400x400.jpg";
import avatar6 from "../../../static/449214568_122101430240378605_3844621943052267173_n.jpg";
import avatar7 from "../../../static/r9umtdCW_400x400.jpg";
import avatar8 from "../../../static/241412723_4377994018889984_2987521970632945107_n.jpg";
import avatar9 from "../../../static/qCFPwqBL_400x400.jpg";
import avatar10 from "../../../static/p5y7NhRY_400x400.jpg";
import avatar11 from "../../../static/285699036_2369257886550163_4709500325822986767_n.jpg";
import avatar12 from "../../../static/6rurvVqr_400x400.jpg";
import avatar13 from "../../../static/q-WYjpB0_400x400.jpg";
import avatar14 from "../../../static/eRykgaYe_400x400.jpg";
import avatar15 from "../../../static/GQnKMTKaoAA-Otz.jpg";
import avatar16 from "../../../static/357478278_10227178788229457_140838401674222657_n.jpg";
import avatar17 from "../../../static/M6e6hj_y_400x400.jpg";
import avatar18 from "../../../static/qWMoyNni_400x400.jpg";
import avatar19 from "../../../static/WPgTiC2Q_400x400.jpg";
import avatar20 from "../../../static/434912344_3662254317321978_1483520972406410580_n.jpg";
import avatar21 from "../../../static/TVW34bhl_400x400.jpg";
import avatar22 from "../../../static/316110164_2510562869086330_7569933887698594626_n.jpg";
import avatar23 from "../../../static/l-qn25MN_400x400.jpg";
import avatar24 from "../../../static/35879_408040319025_7574280_n.jpg";
import avatar25 from "../../../static/zM8muV0a_400x400.jpg";
import avatar26 from "../../../static/xpUgHJ5o_400x400.jpg";
import avatar27 from "../../../static/XCTm2SgE_400x400.jpg";
import avatar28 from "../../../static/juiKt2Xo_400x400.jpg";
import avatar29 from "../../../static/avatar (2).png";
import avatar30 from "../../../static/125862637_2702087790041475_6303855083800082291_n.jpg";

const FifthBlock =  () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const communityData = [
    {
      img: avatar,
      name: "Liu Jun",
      date: "June 11, 2024",
      description:
        "Started using this bot a week ago and already seeing consistent profits. 👍💵"
    },
    {
      img: avatar2,
      name: "Mason Miller",
      date: "May 25, 2024",
      description:
        "🚀 Just made my first trade with this bot and already seeing gains! #crypto #automatedtrading"
    },
    {
      img: avatar3,
      name: "Ava Thompson",
      date: "March 15, 2024",
      description:
        "This bot is a game-changer for passive income in crypto. 🤖💸"
    },
    {
      img: avatar4,
      name: "Yang Lan",
      date: "February 2, 2024",
      description:
        "Automated trading bots are the future of finance. Excited to see where this one takes me! 🌐💰"
    },
    {
      img: avatar5,
      name: "Lucas Rodriguez",
      date: "June 19, 2024",
      description:
        "Finally, a bot that actually works! Making trading so much easier."
    },
    {
      img: avatar6,
      name: "Wang Fang",
      date: "June 7, 2024",
      description:
        "Tried a few bots before, but this one is legit. 📈🔥"
    },
    {
      img: avatar7,
      name: "Emma Johnson",
      date: "June 22, 2024",
      description:
        "Loving the simplicity of this trading bot. Set it and forget it! 💼💵"
    },
    {
      img: avatar8,
      name: "Zhou Ming",
      date: "June 10, 2024",
      description:
        "Automated trading is the way to go. This bot proves it! 💻💎"
    },
    {
      img: avatar9,
      name: "Logan Brown",
      date: "June 17, 2024",
      description:
        "Just checked my profits from last night's trades... this bot is a goldmine! 💰✨"
    },
    {
      img: avatar10,
      name: "Isabella Harris",
      date: "July 4, 2024",
      description:
        "Imagine this bot making me crypto-rich while I'm binge-watching Netflix #FutureOfTrading"
    },
    {
      img: avatar11,
      name: "Zhang Lei",
      date: "June 29, 2024",
      description:
        "If you're into crypto, you need this bot. It's a game-changer!"
    },
    {
      img: avatar12,
      name: "Oliver Thompson",
      date: "June 14, 2024",
      description:
        "Cheers to this bot for making trading stress-free and profitable! 🥂💼"
    },
    {
      img: avatar13,
      name: "Amelia Robinson",
      date: "July 2, 2024",
      description:
        "Trust me, this bot pays off. Don't miss out! 💵🚀"
    },
    {
      img: avatar14,
      name: "Chen Tao",
      date: "April 19, 2024",
      description:
        "This bot is so intuitive, even a newbie like me is making money! "
    },
    {
      img: avatar15,
      name: "Evelyn Clark",
      date: "March 6, 2024",
      description:
        "Woke up to more gains thanks to this automated trading bot. Best feeling ever! 😎💸💸💸"
    },
    {
      img: avatar16,
      name: "Sun Rong",
      date: "February 20, 2024",
      description:
        "Just set up my bot and already impressed with the results. Hands-free trading at its finest! 🙌"
    },
    {
      img: avatar17,
      name: "Charlotte Garcia",
      date: "January 5, 2024",
      description:
        "This bot is my new favorite co-pilot in the crypto world."
    },
    {
      img: avatar18,
      name: "William Anderson",
      date: "June 22, 2024",
      description:
        "Automated trading with this bot = more time for hobbies. Win-win!"
    },
    {
      img: avatar19,
      name: "Harper White",
      date: "June 8, 2024",
      description:
        "Efficiency level: automated trading bot. Seriously, where has this been all my life? 💎"
    },
    {
      img: avatar20,
      name: "Yang Bo",
      date: "May 26, 2024",
      description:
        "Loving the hands-off approach to trading with this bot.🙏 It's a lifesaver! "
    },
    {
      img: avatar21,
      name: "Benjamin Davis",
      date: "July 02, 2024",
      description:
        "This bot turns trading from stressful to exciting. Seeing green all day!"
    },
    {
      img: avatar22,
      name: "Wu Bin",
      date: "June 30, 2024",
      description:
        "Trading just got a whole lot smarter with this bot. The future is here! "
    },
    {
      img: avatar23,
      name: "Noah Martinez",
      date: "July 01, 2024",
      description:
        "Automated trading bots like this one are leveling up my investment game. 🔝💸"
    },
    {
      img: avatar24,
      name: "Li Wei",
      date: "June 3, 2024",
      description:
        "Can't stop talking about how awesome this bot is. It's a must-try!"
    },
    {
      img: avatar25,
      name: "Mia Wilson",
      date: "June 20, 2024",
      description:
        "My friends don't believe how much I've made with this bot. Results speak for themselves!"
    },
    {
      img: avatar26,
      name: "Xu Feng",
      date: "April 6, 2024",
      description:
        "This bot = more profits, less stress. Count me in!"
    },
    {
      img: avatar27,
      name: "Liam Taylor",
      date: "March 24, 2024",
      description:
        "Automating my crypto investments was the best decision. This bot is a genius."
    },
    {
      img: avatar28,
      name: "Sophia Martinez",
      date: "February 9, 2024",
      description:
        "Impressed by how this bot handles market swings. Definitely worth it."
    },
    {
      img: avatar29,
      name: "Sun Jie",
      date: "January 26, 2024",
      description:
        "Finally found a bot that lives up to the hype. Making money while I sleep."
    },
    {
      img: avatar30,
      name: "Zhang Ying",
      date: "June 13, 2024",
      description:
        "This bot's algorithms are spot on. It's like having a crystal ball for crypto."
    }
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? communityData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === communityData.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={style.all_block}>
      <div className={style.paragraph}>
        <span>Community</span>
      </div>
      <div className={style.community}>
        <div className={style.arrow} onClick={handlePrev}>
          &lt;
        </div>
        <CommunityCard {...communityData[currentIndex]} />
        <div className={style.arrow} onClick={handleNext}>
          &gt;
        </div>
      </div>
    </div>
  );
};

export default FifthBlock;