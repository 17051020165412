import React from "react";
import style from "./EighthBlock.module.css";
import CeoCard from "../../CeoCard/CeoCard";
import ceo from "../../../static/ceo (1).png";
import ceo2 from "../../../static/ceo (2).png";

const EighthBlock = () => {
  return (
    <div className={style.all_block}>
      <CeoCard
        img={ceo}
        name="Eddie Wu"
        position="Director and Chief Executive Officer"
        description="“To say I was surprised is an understatement. The Defi-labs team will go down in history!”"
      />
      <CeoCard
        img={ceo2}
        name="Joe Tsai"
        position="Chairman"
        description="“I am delighted that the Defi-labs team has been able to achieve such success with one of our most complex technology products. This is a unique opportunity to build a sustainable financial future for retail investors.”"
      />
    </div>
  );
};

export default EighthBlock;
