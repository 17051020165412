import React, { useRef } from "react";
import CardTeam from "../../CardTeam/CardTeam";
import style from "./FourthBlock.module.css";
import avatar1 from "../../../static/avatar (1).png";
import avatar2 from "../../../static/avatar (2).png";
import avatar3 from "../../../static/avatar (3).png";
import team from "../../../static/team (1).jpg";
import team2 from "../../../static/team (2).jpg";
import team3 from "../../../static/team (3).jpg";
import team4 from "../../../static/team (4).jpg";
import team5 from "../../../static/team (5).jpg";

const FourthBlock = () => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div className={style.all_block}>
      <div className={style.paragraph}>
        <span>DeFi-Labs team</span>
      </div>
      <div className={style.about}>
        <span>
        The Alibaba DeFi-labs team has over 600 talented DeFI developers from all over the world.
        </span>
      </div>
      <div className={style.carousel_container}>
        <div className={style.team} ref={carouselRef}>
          <CardTeam img={avatar1} name="Wei Zhang" position="Chief Product Officer" />
          <CardTeam img={avatar2} name="John Miller" position="Software Engineer" />
          <CardTeam img={avatar3} name="Liang Chen" position="Chief Technology Officer" />
          <CardTeam img={team} name="Xiao Li" position="Head of PR" />
          <CardTeam img={team2} name="Dr. Andrew Cullen" position="Senior Applied Research Engineer" />
          <CardTeam img={team3} name="Mei Wang" position="UI/UX Designer" />
          <CardTeam img={team4} name="Jian Huang" position="Lead Developer" />
          <CardTeam img={team5} name="Ling Liu" position="Project Manager" />
        </div>
        <div className={style.arrows}>
          <div className={style.arrow} onClick={scrollLeft}>
            &lt;
          </div>
          <div className={style.arrow} onClick={scrollRight}>
            &gt;
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthBlock;
