import React from "react";
import style from "./SeventhBlock.module.css";
import partner from "../../../static/Solana-logo.png";
import partner2 from "../../../static/Ethereum-Foundation-900x0.png";
import partner3 from "../../../static/phantom-logo-long-768x179.png";
import partner4 from "../../../static/MetaMask-Logo.png";
import partner5 from "../../../static/binance-logo-0.png";
import partner6 from "../../../static/Debank-900x0.png";
import partner7 from "../../../static/nyse-logo-0.png";
import partner8 from "../../../static/okx-logo-1ABB5F7675-seeklogo.com.png";
import partner9 from "../../../static/purepng.com-blackrock-logologobrand-logoiconslogos-251519938910ako99.png";

const SeventhBlock = () => {
  const partners = [partner, partner2, partner3, partner4, partner5, partner6, partner7, partner8, partner9];
  
  return (
    <div className={style.all_block}>
      <div className={style.paragraph}>
        <span>Our partners</span>
      </div>
      <div className={style.floating_carousel}>
        <div className={style.floating_carousel_inner}>
          {partners.map((src, index) => (
            <div key={index}>
              <img src={src} alt={`partner ${index + 1}`} />
            </div>
          ))}
          {partners.map((src, index) => (
            <div key={`${index}-duplicate`}>
              <img src={src} alt={`partner ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeventhBlock;
