import React from "react";
import style from "./SecondBlock.module.css";
import AI from "../../../static/ai.png";

const SecondBlock = () => {
  return (
    <div className={style.body}>
          <div className={style.all_block}>
        <div className={style.block_image}>
          <img src={AI} alt="ai"/>
        </div>
        <div className={style.block_info}>
          <span>
            <p>The foundation of our bot is a neural network that has been trained on hundreds of terabytes of trading data.</p>
            <p>We have developed a unique product for crypto portfolio management and aim to demonstrate our superiority in the market.</p>
            <p>Using cross-exchange and cross-chain arbitrage strategies, futures trading, and many more, the trading bot executes more than 18 thousand orders per second.</p>
          </span>
        </div>
    </div>
    </div>
  );
};

export default SecondBlock;
