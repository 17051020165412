import React from "react";
import style from "./CardTeam.module.css";

const CardTeam = ({ img, name, position }) => {
  return (
    <div className={style.all_block}>
      <div className={style.avatar}>
        <img src={img} alt="avatar"/>
      </div>
      <div className={style.block_name}>
        <span>
          <strong>{name}</strong>
        </span>
      </div>
      <div className={style.position_block}>
        <span>{position}</span>
      </div>
    </div>
  );
};

export default CardTeam;
