import style from "./Footer.module.css";
import footer from "../../static/footer.png";

const Footer = () => {
  return (
    <div className={style.all_block}>
      <div className={style.img}>
        <img src={footer} />
      </div>
      <div className={style.info}>
        <span>
          Copyright Notice © 1999-2024 Alibaba Group Holding Limited and/or its
          affiliates and licensors. <p>All rights reserved.</p>
        </span>
      </div>
    </div>
  );
};

export default Footer;
