import React from "react";
import style from "./CeoCard.module.css";

const CeoCard = ({ img, name, position, description }) => {
  return (
    <div className={style.all_block}>
      <div className={style.user}>
        <div className={style.avatar}>
          <img src={img} alt="avatar" />
        </div>
        <div className={style.info}>
          <span>{name}</span>
          <span>{position}</span>
        </div>
      </div>
      <div className={style.comment}>
        <div>
          <svg
            width="26"
            height="30"
            viewBox="0 0 26 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L25 15L1 29" stroke="#FF6A00" />
          </svg>
        </div>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default CeoCard;
