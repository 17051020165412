import React, { useState, useEffect } from "react";
import style from "./FirstBlock.module.css";
import Telefon from "../../../static/Telefon.png";

const FirstBlock = () => {
  const [isScreenWide, setIsScreenWide] = useState(window.innerWidth >= 1072);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWide(window.innerWidth >= 1072);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={style.all_block}>
      <div className={style.text_block}>
        <div className={style.block_paragraph}>
          <span>GET YOUR CRYPTO YIELDS NOW</span>
        </div>
        <div className={style.block_about}>
          <span>
          Join the new trading robot from Alibaba and earn cryptocurrency!
          </span>
        </div>
        {isScreenWide && (
          <div className={style.block_connect}>
            <button class="connect">Connect wallet</button>
          </div>
        )}
      </div>
      <div className={style.phone_container}>
        <div className={style.outer_circle}>
          <div className={style.inner_circle}></div>
        </div>
        <img src={Telefon} className={style.telefon_image} alt="Telefon" />
      </div>
      {!isScreenWide && (
        <div className={style.block_connect}>
          <button class="connect">Connect wallet</button>
        </div>
      )}
    </div>
  );
};

export default FirstBlock;
